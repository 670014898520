import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  paymentData: [],
  singlePayment: null,
  error: null,
  message: null,
  onPage: 1,
  isEdit:false,
}

const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetPayment: (state, action) => {
      state.error = null
      state.message = null
      state.singlePayment = null
    },
    updatePage: (state, { payload }) => {
      state.onPage = payload
    },
    isEditChange: (state, { payload }) => {
      state.isEdit = payload
    },
  },
  extraReducers: (builder) => {
    builder

      // // Fetch all Payment data
      .addCase(fetchPaymentData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchPaymentData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.paymentData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchPaymentData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // // Fetch Single Payment
      .addCase(getSinglePayment.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSinglePayment.fulfilled, (state, { payload: { data, error } }) => {
        state.loading = false
        if (data) {
          state.singlePayment = data
        } else {
          state.error = error
        }
      })
      .addCase(getSinglePayment.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // ADD NEW Payment Data
      .addCase(addPayment.pending, (state, action) => {
        state.loading = true
      })
      .addCase(addPayment.fulfilled, (state, { payload: { error, message } }) => {
        state.loading = false
        if (message) {
          state.message = message
        } else {
          state.error = error
        }
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // edit Payment Data
      .addCase(editPayment.pending, (state, action) => {
        state.loading = true
      })
      .addCase(editPayment.fulfilled, (state, { payload: { error, message } }) => {
        state.loading = false
        if (message) {
          state.message = message
        } else {
          state.error = error
        }
      })
      .addCase(editPayment.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // DELETE PRODUCT CASES
      .addCase(DeletePayment.pending, (state, action) => {
        state.loading = true
      })
      .addCase(DeletePayment.fulfilled, (state, { payload: { error, message } }) => {
        state.loading = false
        if (message) {
          state.message = message
        } else {
          state.error = error
        }
      })
      .addCase(DeletePayment.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })
  },
})

export const fetchPaymentData = createAsyncThunk('fetch/payment', async (params) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/payment/list_payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
       token: localStorage.getItem('token'),
    },
    body: JSON.stringify(params),
  })
  const result = await response.json()
  return result
})

export const addPayment = createAsyncThunk('add/payment', async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/payment/add_payment', {
    method: 'POST',
    headers: {
      token: localStorage.getItem('token'),
    },
    body: values,
  })
  const result = await response.json()
  return result
})

export const editPayment = createAsyncThunk('edit/payment', async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/payment/edit_payment', {
    method: 'POST',
    headers: {
      token: localStorage.getItem('token'),
    },
    body: values,
  })
  const result = await response.json()
  return result
})

export const DeletePayment = createAsyncThunk('delete/payment', async (value) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + '/payment/delete_payment/' + value,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})

export const getSinglePayment = createAsyncThunk('single/payment', async (value) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + '/payment/single_payment/' + value,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})

export const { resetPayment,updatePage,isEditChange } = paymentSlice.actions
export default paymentSlice.reducer

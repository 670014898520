import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {}

const filterSlice = createSlice({
  name: 'upcomming',
  initialState,
  reducers: {
    addItem: (state, action) => {
        // console.log("action.payload", action.payload)
        return action.payload;
    },
  },
})

export const { addItem } = filterSlice.actions
export default filterSlice.reducer

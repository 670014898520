import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  token: "",
  error: null,
  success: "",
  message: null,
  valid_token: null,
  users: [],
  singleUser:null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state, action) => {
      state.error = null;
      state.success = "";
      state.loading = false;
      state.token = "";
      state.message = null;
    },
    logout: (state, action) => {
      state.valid_token = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // Add new User case
      .addCase(addUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, { payload: { result, message, errors } }) => {
        state.loading = false;
        if (result) {
          state.success = result;
        }
        if (errors) {
          state.error = errors;
        }
        if (message) {
          state.message = message;
        }
      })
      .addCase(addUser.rejected, (state, action) => {
        state.error = "Something went wrong with the server";
      })

      //login user Case
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload: { token, result, message, error } }) => {
        state.loading = false;
        if (token && message && result) {
          state.success = result;
          state.token = token;
          state.message = message;
        }
        if (error) {
          state.error = error;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = "Something went wrong with the server";
      })

      // verify Token case
      .addCase(verifyToken.fulfilled, (state, { payload }) => {
        if (payload.error) {
          state.valid_token = null;
          state.error = payload.error;
        } else {
          state.valid_token = payload;
        }
      })

      //get All users Case
      .addCase(getAllUsers.fulfilled, (state, { payload: { result, error } }) => {
        if (result) {
          state.users = result;
        } else {
          state.error = error;
        }
      })


      //update users Case
      .addCase(updateUser.fulfilled, (state, { payload: { result, error,message } }) => {
        if (result && message) {
          state.message = message;
          state.success = result;
        } else {
          state.error = error;
        }
      })

      //delete user Case
      .addCase(deleteUser.fulfilled, (state, { payload: { result, error,message } }) => {
        if (result && message) {
          state.message = message;
          state.success = result;
        } else {
          state.error = error;
        }
      })

      //get single users Case
      .addCase(fetchSingleUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSingleUser.fulfilled, (state, { payload: { result, error } }) => {
        if (result) {
          state.singleUser = result;
        } else {
          state.error = error;
        }
        state.loading = false;
      })
  },
});

export const addUser = createAsyncThunk("addUser", async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/add_user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(values),
  });
  const result = await response.json();
  return result;
});

export const loginUser = createAsyncThunk("login", async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });

  return await response.json();
});

export const verifyToken = createAsyncThunk("verify", async () => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/verify_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });

  return await response.json();
});


export const getAllUsers = createAsyncThunk("getUsers", async () => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/get_users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });

  return await response.json();
});


export const updateUser = createAsyncThunk("user/update", async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/edit_user", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(values),
  });

  return await response.json();
});


export const deleteUser = createAsyncThunk("user/delete", async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/delete_user", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({id:values}),
  });

  return await response.json();
});

export const fetchSingleUser = createAsyncThunk("getUser/single", async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/user/get_single_user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({id:values}),
  });

  return await response.json();
});


export const { resetUser, logout } = userSlice.actions;
export default userSlice.reducer;

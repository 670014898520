import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    changeState: (state) => {
        if(state.sidebarShow){
            state.sidebarShow = false;
        }else if(!state.sidebarShow){
            state.sidebarShow = true;
        }
     
    },
  },
})

export const { changeState } = sidebarSlice.actions

export default sidebarSlice.reducer

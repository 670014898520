import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  productData: [],
  error: null,
  message: null,
}

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProduct: (state, action) => {
      state.error= null
      state.message=null
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch all List data
      .addCase(fetchProductData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchProductData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.productData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // ADD NEW PRODUCT CASES
      .addCase(addProduct.pending, (state, action) => {
        state.loading = true
      })
      .addCase(addProduct.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false
        if (result && message) {
          state.message = message
        } else {
          state.error = error
        }
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      // DELETE PRODUCT CASES
      .addCase(deleteProduct.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteProduct.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false
        if (result && message) {
          state.message = message
        } else {
          state.error = error
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })
  },
})

export const fetchProductData = createAsyncThunk('fetch/products', async (params) => {
  const { page, size } = params
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/product/get_products?page=${page}&size=${size}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})

export const addProduct = createAsyncThunk('add/product', async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/product/add_product', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
    },
    body: JSON.stringify(values),
  })
  const result = await response.json()
  return result
})

export const deleteProduct = createAsyncThunk('delete/product', async (value) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/product/delete_product', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
  const result = await response.json()
  return result
})

export const {resetProduct} = productSlice.actions
export default productSlice.reducer

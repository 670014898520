import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  mockupData: [],
  filteredData: [],
  error: null,
  message: null,
}

const itemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    resetItemState: (state, action) => {
      state.message = null
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch all List data
      .addCase(fetchMockupData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchMockupData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.mockupData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchMockupData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      //Fetch Item filtered data
      .addCase(fetchtfilteredItems.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.filteredData = result
        } else {
          state.error = error
        }
      })

      //Update mockup status
      .addCase(updateMockupStatus.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false
        if (result && message) {
          state.message = message
        } else {
          state.error = error
        }
      })

      //Update Lsiting status
      .addCase(updateListingStatus.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false
        if (result && message) {
          state.message = message
        } else {
          state.error = error
        }
      })
  },
})

export const fetchMockupData = createAsyncThunk('fetch/mockup', async (params) => {
  const { page, size, brand, model,type } = params
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/items/get_mockups?page=${page}&brand=${brand}&model=${model}&size=${size}&type=${type}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})

export const updateMockupStatus = createAsyncThunk('update/mockupStatus', async (formData) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + '/items/update_mockup',
    {
      method: 'PUT',
      headers: {
        token: localStorage.getItem('token'),
      },
      body: formData
    },
  )
  const result = await response.json()
  return result
})

export const updateListingStatus = createAsyncThunk('update/ListingStatus', async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/items/update_listing', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
    },
    body: JSON.stringify(values),
  })
  const result = await response.json()
  return result
})

export const fetchtfilteredItems = createAsyncThunk('fetch/filter_listing', async (params) => {
  const {
    page,
    size,
    brand,
    availability,
    model,
    PRODUCT_SKU,
    Front_Template,
    Back_Template,
    Skin_Template,
    Skin_Mockups,
    Camera_Lens_Guard,
    Single_wrap,
    single_wrap_mockups,
  } = params
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/items/filter_listing?page=${page}&brand=${brand}&availability=${availability}&model=${model}&size=${size}&sku=${PRODUCT_SKU}&Front_Template=${Front_Template}&Back_Template=${Back_Template}&Skin_Template=${Skin_Template}&Skin_Mockups=${Skin_Mockups}&Camera_Lens_Guard=${Camera_Lens_Guard}&Single_wrap=${Single_wrap}&single_wrap_mockups=${single_wrap_mockups}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})


export const { resetItemState } = itemSlice.actions
export default itemSlice.reducer

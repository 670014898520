import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  entryData: [],
  addEntryData: null,
  addItemsData: null,
  message:null,
  error: null,
}

const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    resetAddEntryItems: (state, action) => {
      state.addEntryData= null
      state.addItemsData= null
      state.message=null
      state.error= null;
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch all Entry data
      .addCase(fetchEntryData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchEntryData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.entryData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchEntryData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      //Add new entry and items cases
      .addCase(addNewEntryAndItems.pending, (state, action) => {
        state.loading = true
      })
      .addCase(addNewEntryAndItems.fulfilled, (state, { payload: { result, error,message,item_response } }) => {
        state.loading = false
        if(error) {
          state.error = error
        }else
        if (result && message && item_response) {
          state.addEntryData = result
          state.addItemsData = item_response
          state.message = message

        } 
      })
      .addCase(addNewEntryAndItems.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

      //Update entry and items cases
      .addCase(UpdateNewEntryAndItems.pending, (state, action) => {
        state.loading = true
      })
      .addCase(UpdateNewEntryAndItems.fulfilled, (state, { payload: { result, error,message } }) => {
        state.loading = false
        if(error) {
          state.error = error
        }else
        if (result && message) {
          state.message = message

        } 
      })
      .addCase(UpdateNewEntryAndItems.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })


      //Update template cases
      .addCase(UpdateTemplateStatus.pending, (state, action) => {
        state.loading = true
      })
      .addCase(UpdateTemplateStatus.fulfilled, (state, { payload: { result, error,message } }) => {
        state.loading = false
        if(error) {
          state.error = error
        }else
        if (result && message) {
          state.message = message

        } 
      })
      .addCase(UpdateTemplateStatus.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })


      //Delete entry and items cases
      .addCase(DeleteEntryAndItems.pending, (state, action) => {
        state.loading = true
      })
      .addCase(DeleteEntryAndItems.fulfilled, (state, { payload: { result, error,message } }) => {
        state.loading = false
        if(error) {
          state.error = error
        }else
        if (result && message) {
          state.message = message

        } 
      })
      .addCase(DeleteEntryAndItems.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })
  },
})

export const fetchEntryData = createAsyncThunk('fetch/entries', async (params) => {
  const { page, size, brand, model,availability,template } = params
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/entry/get_entry?page=${page}&availability=${availability}&brand=${brand}&template=${template}&model=${model}&size=${size}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  )
  const result = await response.json()
  return result
})


export const addNewEntryAndItems = createAsyncThunk('add/entry_plus_items', async (values) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/items/add_item`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(values),
    },
  )
  const result = await response.json()
  return result
})


export const UpdateNewEntryAndItems = createAsyncThunk('update/entry_items', async (values) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/items/edit_itemEntry`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(values),
    },
  )
  const result = await response.json()
  return result
})

export const UpdateTemplateStatus = createAsyncThunk('update/template', async (formData) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/entry/update_entry`,
    {
      method: 'PUT',
      headers: {
        token: localStorage.getItem('token'),
      },
      body: formData
    },
  )
  const result = await response.json()
  return result
})

export const DeleteEntryAndItems = createAsyncThunk('delete/entry_items', async (values) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL +
      `/items/delete_entry_item`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(values),
    },
  )
  const result = await response.json()
  return result
})

export const { resetAddEntryItems } = entrySlice.actions
export default entrySlice.reducer

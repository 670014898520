import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  orderGenData: {},
  error: null,
}

const orderGenSlice = createSlice({
  name: 'ordersgens',
  initialState,
  reducers: {
    orderGenerate: (state, action) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch all List data
      .addCase(fetchOrderGenData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchOrderGenData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.orderGenData = result
          state.error = null
        } else {
          state.orderGenData = null
          state.error = error
        }
      })
      .addCase(fetchOrderGenData.rejected, (state, action) => {
        state.loading = false
        state.orderGenData = null
        state.error = 'Something went wrong with the server'
      })
  },
})

export const fetchOrderGenData = createAsyncThunk('fetch/order_gen', async (data) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_API_URL + `/order_gen`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const result = await response.json()
      return { error: result.error.map((e) => e.path + ' has ' + e.msg).join('.\n') }
    }
  } catch (err) {
    console.log(err, 'err')
    return { error: err.message }
  }
})

export default orderGenSlice.reducer

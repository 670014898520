import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  brandData: [],
  error: null,
  message:null,
};

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    resetBrand: (state, action) => {
    state.error= null
    state.message=null
  },
},
  extraReducers: (builder) => {
    builder

      // Fetch all List data
      .addCase(fetchBrandData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBrandData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false;
        if (result) {
          state.brandData = result;
        } else {
          state.error = error;
        }
      })
      .addCase(fetchBrandData.rejected, (state, action) => {
        state.error = "Something went wrong with the server";
      })


      // ADD BRAND CASES
      .addCase(addBrand.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addBrand.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false;
        if (result && message) {
          state.message = message;
        } else {
          state.error = error;
        }
      })
      .addCase(addBrand.rejected, (state, action) => {
        state.error = "Something went wrong with the server";
      })

      // DELETE BRAND CASES
      .addCase(deleteBrand.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBrand.fulfilled, (state, { payload: { result, error, message } }) => {
        state.loading = false;
        if (result && message) {
          state.message = message;
        } else {
          state.error = error;
        }
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.error = "Something went wrong with the server";
      })

      // Data fetch for Select
      .addCase(fetchSelectData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false;
        if (result) {
          state.brandData = result;
        } else {
          state.error = error;
        }
      });
  },
});

export const fetchBrandData = createAsyncThunk("fetchBrandData", async (params) => {
  const { page, size } = params;
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + `/brand/get_brands?page=${page}&size=${size}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
  const result = await response.json();
  return result;
});

export const fetchSelectData = createAsyncThunk("fetch_select_data", async () => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + `/brand/get_brands?select=yes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
  const result = await response.json();
  return result;
});

export const addBrand = createAsyncThunk('add/brand', async (values) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/brand/add_brand', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
    },
    body: JSON.stringify(values),
  })
  const result = await response.json()
  return result
})

export const deleteBrand = createAsyncThunk("delete/brand", async (value) => {
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/brand/delete_brand", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(value),
  });
  const result = await response.json();
  return result;
});

 export const {resetBrand} = brandSlice.actions
export default brandSlice.reducer;
